<template>
  <div class="hello mb-5 mt-5">

    
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="ytm-title">¿Ya está todo México vacunado contra la Covid-19?</h1>
          <!-- <div v-if="diffday != 0"> -->
            <h1 class="mb-0 ymt_ans">NO</h1>
            <p class="mb-4 pb-3">Aún no está todo México vacunado. El presidente Andrés Manuel López Obrador dijo <br>que en octubre de este año toda la población mexicana estará vacunada contra la enfermedad COVID-19.</p>
          <!-- </div> -->
          <!-- <div v-else> -->
            <!-- <h1 class="mb-0 ymt_ans">Si</h1>
            <h6 class="mb-4 pb-3">Ya está todo México vacunado. El presidente Andrés Manuel López Obrador dijo <br>que en octubre de este año toda la población mexicana estará vacunada contra la enfermedad COVID-19.</h6> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <b-container>
      <GraficsSum />
    </b-container>
   
    <b-container >
      <b-row class="mt-3 mb-5 d-flex align-items-center ">
        <b-col sm="12" md="3" class="offset-md-1">
          <img :src="imgAMLO" alt="Amlo hablando">
        </b-col>
        <b-col sm="12" md="7">
          <div class="ytv_message">
          <p class="text-message text-start">
              <vue-typer :text='text'
            caret-animation='smooth'
            :repeat='Infinity'
            :shuffle='false'
            initial-action='typing'
            :pre-type-delay='1'
            :type-delay='40'
            :pre-erase-delay='1000'
            :erase-delay='20'
            erase-style='backspace'
            :erase-on-complete='true'
            @typed='onTyped'
            ></vue-typer>
          </p>
        </div>
        <div class="arrow_box">
          <div class="ytv_text">
            <p class="text-amlo">Se están conformando más brigadas, se van a instalar más centros de vacunación para poder cumplir con este propósito de terminar en octubre toda la vacunación, de modo que antes de que llegue el invierno estemos protegidos todos los mexicanos. Ese es el propósito que se tiene.</p>
          </div>
          </div>
          <h3 class="mt-2">Andrés Manuel López Obrador</h3>
          <b-link class="ytv_link" :href="'https://www.gob.mx/presidencia/es/articulos/version-estenografica-conferencia-de-prensa-del-presidente-andres-manuel-lopez-obrador-del-18-de-mayo-de-2021?idiom=es'">
            Conferencia de prensa del 18 de mayo de 2021
          </b-link>
        </b-col>
        
      </b-row>
    </b-container>


    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="mt-5 mb-5">¿Y cuándo tendrá todo México el esquema completo de vacunación contra COVID-19?</h3>
        </div>
      </div>
    </div>

    <b-container>
      <Grafics />
    </b-container>

    <Social />
    
<Balls />

  </div>
</template>

<script>
import img from '../assets/amlo.gif'
import { mapGetters } from 'vuex'
import Grafics from '../components/Grafics'
import GraficsSum from '../components/GraficsSum'
import Balls from '../components/Balls'
import Social from '../components/Social'

export default {
  name: 'Init',
  components:{
    Grafics,
    GraficsSum,
    Balls,
    Social
  },
  data(){
    return{
      imgAMLO: img,
      text: ["MENSAJE IMPORTANTE AL PUEBLO DE MÉXICO", "EN OCTUBRE ESTARÁ TODO MÉXICO VACUNADO CONTRA LA COVID-19"]
      /*
      text: ["Se están conformando más brigadas", "se van a instalar más centros de vacunación",
      "para poder cumplir con este propósito de", "terminar en octubre toda la vacunación",
      "de modo que antes de que llegue el invierno", "estemos protegidos todos los mexicanos.",
      "Ese es el propósito que se tiene."]*/
    }
  },
  computed:{
    ...mapGetters({
      // diffday: 'getDiff'
    })
  },
  methods:{
    onTyped: function(typedString) {
        if (typedString){
          //
        }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  font-size: 150px;
}
img{
  width: 300px;
}




</style>
