<template>
    <div class="component-socials">

        <b-container>

            <b-row class="mb-3 mt-3">
                 <ShareNetwork
                    network="facebook"
                    url="https://todomexicovacunado.serendipia.digital/"
                    title="¿Ya está todo México vacunado contra la COVID-19? ¿Cuántos días faltan para llegar a la meta esperada?"
                    description="Averigua si ya está todo México vacunado de acuerdo con los datos de la Secretaría de Salud. ¿Cuántos días faltan para llegar a la meta esperada? ¿Cuántas personas con esquema completos se han vacunado?"
                    quote="¿Ya está todo México vacunado contra la COVID-19? Conoce cómo avanza la campaña de vacunación"
                    hashtags="vacunacovid,COVID19,vacunacioncovid,vacunacovidmexico"
                >
                <img src="../assets/Facebook-icon-min.svg" alt="Facebook share logo">
                </ShareNetwork>

            </b-row>

            <b-row class="mb-3">

            <ShareNetwork
                network="twitter"
                twitter-user="SerendipiaData"
                url="https://todomexicovacunado.serendipia.digital/"
                title="¿Ya está todo México vacunado contra la COVID-19? ¿Cuántos días faltan para llegar a la meta esperada?"
                description="Averigua si ya está todo México vacunado de acuerdo con los datos de la Secretaría de Salud. ¿Cuántos días faltan para llegar a la meta esperada? ¿Cuántas personas con esquema completos se han vacunado?"
                hashtags="vacunacovid,COVID19,vacunacioncovid,vacunacovidmexico"
            >
                <img src="../assets/twitter-icon-min.svg" alt="Twitter share logo">
            </ShareNetwork>

             </b-row>

            <b-row class="mb-3">

            <ShareNetwork
                network="whatsapp"
                url="https://todomexicovacunado.serendipia.digital/"
                title="¿Ya está todo México vacunado contra la COVID-19? ¿Cuántos días faltan para llegar a la meta esperada?"
                description="Averigua si ya está todo México vacunado de acuerdo con los datos de la Secretaría de Salud. ¿Cuántos días faltan para llegar a la meta esperada? ¿Cuántas personas con esquema completos se han vacunado?"
            >
                <img src="../assets/whatsapp-icon-min.svg" alt="Whatsapp share logo">
            </ShareNetwork>

             </b-row>

            <b-row class="mb-3">

            <ShareNetwork
                network="telegram"
                url="https://todomexicovacunado.serendipia.digital/"
                title="¿Ya está todo México vacunado contra la COVID-19? ¿Cuántos días faltan para llegar a la meta esperada?"
                description="Averigua si ya está todo México vacunado de acuerdo con los datos de la Secretaría de Salud. ¿Cuántos días faltan para llegar a la meta esperada? ¿Cuántas personas con esquema completos se han vacunado?"
            >
                <img src="../assets/telegram-icon-min.svg" alt="Telegram share logo">
            </ShareNetwork>

             </b-row>

            <b-row class="mb-3">

            <ShareNetwork
                network="email"
                url="https://todomexicovacunado.serendipia.digital/"
                title="¿Ya está todo México vacunado contra la COVID-19? ¿Cuántos días faltan para llegar a la meta esperada?"
                description="Averigua si ya está todo México vacunado de acuerdo con los datos de la Secretaría de Salud. ¿Cuántos días faltan para llegar a la meta esperada? ¿Cuántas personas con esquema completos se han vacunado?"
            >
                <img src="../assets/mail-icon-min.svg" alt="Email share logo">
            </ShareNetwork>

             </b-row>



        </b-container>



    </div>
</template>

<script>
export default {
    name: 'Social'
}
</script>

<style scoped>
a{
    border-bottom: none;
}
a:hover{
    border-bottom: none;
}

img{
    width: 30px;
}

img:hover{
    opacity: 0.5;
}

.component-socials{
    z-index: 99999;
    position: fixed;
    top: 50%;
    right: 0;
}
</style>
