import Vue from 'vue'
import Vuex from 'vuex'
import datafile from '../../public/esquemas-completos.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    date: new Date(datafile[datafile.length-1].fecha),
    dateActualizacion: new Date(datafile[datafile.length-1].fecha_actualizacion),
    inicioV: new Date("2020/12/24"),
    octubre: new Date("2021/10/31"),
  },
  getters:{
    getDiff(state){
      let difftime = state.octubre.getTime() - state.date.getTime()
      let contdias = Math.round(difftime/(1000*60*60*24));
      return contdias
    },
    getDiffStart(state){
      let difftime = state.date.getTime() - state.inicioV.getTime()
      let contdias = Math.round(difftime/(1000*60*60*24));
      return contdias
    },
    getTotalDays(state){
      let difftime = state.octubre.getTime() - state.inicioV.getTime()
      let contdias = Math.round(difftime/(1000*60*60*24));
      return contdias
    },
    getFechaUnformat(state){
      return state.date
    },
    getStringDateEs(state){
      let month = state.dateActualizacion.getMonth()+1
      let year = state.dateActualizacion.getFullYear()
      let day = state.dateActualizacion.getDate()

      const MESES_STRING_ES={
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      }

      const DEF_MES = month
      const mes_correct = MESES_STRING_ES[month] || DEF_MES
      
      return day+' de '+mes_correct+' de '+year
    },
    getStringDateEsData(state){
      
      let month = state.date.getMonth()+1
      let year = state.date.getFullYear()
      let day = state.date.getDate()

      const MESES_STRING_ES={
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      }

      const DEF_MES = month
      const mes_correct = MESES_STRING_ES[month] || DEF_MES
      
      return day+' de '+mes_correct+' de '+year
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
