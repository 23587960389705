<template>
  <div id="app">
    
    <Init />

    <Footer />
  </div>
</template>

<script>
import Init from './components/Init.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Init,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'Titillium Web', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--greyspdark);
}
</style>
