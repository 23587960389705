<template>
    <div>
        <div class="box">
          <img id="ball1" class="bounce" :src="covidimg" alt="COVID flotante">
          <img id="ball2" class="bounce" :src="covidimg" alt="COVID flotante">
          <img id="ball3" class="bounce" :src="covidimg" alt="COVID flotante">
          <img id="ball4" class="bounce" :src="covidimg" alt="COVID flotante">
          <img id="ball5" class="bounce" :src="covidimg" alt="COVID flotante">
        </div>
    </div>
</template>


<script>

import covid from '../assets/covid.svg'

export default {
    name: 'Balls',
    data(){
        return{
            covidimg: covid,
        }
    },
}
</script>

<style scoped>
.box {
    top:-110px;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: -5;
}
.bounce {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: inset -5px -5px 5px rgba(0,0,0,.1), 15px 15px 2px rgba(0,0,0,.04);
  position: absolute;
  -webkit-animation: moveX 5.05s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  -moz-animation: moveX 5.05s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  -o-animation: moveX 5.05s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  animation: moveX 5.05s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  }

  .bounce#ball1{
    animation-delay: 1s;
  }

  .bounce#ball2{
    animation-delay: 2s;
  }

  .bounce#ball3{
    animation-delay: 3s;
  }

  .bounce#ball4{
    animation-delay: 4s;
  }
  .bounce#ball5{
    animation-delay: 5s;
  }

@-webkit-keyframes moveX {
  from { left: 0; } to { left: 100vw; }
}
@-moz-keyframes moveX {
  from { left: 0; } to { left: 100vw; }
}
@-o-keyframes moveX {
  from { left: 0; } to { left: 100vw; }
}
@keyframes moveX {
  from { left: 0; } to { left: 100vw; }
}

@-webkit-keyframes moveY {
  from { top: 0; } to { top: 90vh; }
}
@-moz-keyframes moveY {
  from { top: 0; } to { top: 90vh; }
}
@-o-keyframes moveY {
  from { top: 0; } to { top: 90vh; }
}
@keyframes moveY {
  from { top: 0; } to { top: 90vh; }
}

</style>