import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueTyperPlugin from 'vue-typer'
// import VuePapaParse from 'vue-papa-parse'
import VueSocialSharing from 'vue-social-sharing'
// import Ads from 'vue-google-adsense'

Vue.use(VueSocialSharing);


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Typer
Vue.use(VueTyperPlugin)

// Ads
// Vue.use(require('vue-script2'))
// Vue.use(Ads.Adsense)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
