<template>
<div>
    <b-row class="p-0 d-flex justify-content-center">

        <b-col cols="12" class="mb-2 pb-1">
            <div class="col-sm-10 offset-sm-1 mb-2 pb-1">
                <h2 class="mt-4 text-white pt-4">¿Quieres saber más?</h2>
            </div>
        </b-col>
        
        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://serendipia.digital/covid-19/cuantas-personas-que-han-muerto-por-covid-estaban-vacunadas/'">
                
                <b-card img-src="https://serendipia.digital/wp-content/uploads/2021/09/nota-vacunados-vs-no-vacunados-1.jpg" 
                img-alt="Todo México vacunado contra COVID-19 en octubre, prometió AMLO: ¿lo cumplirá?" 
                img-top >
                <h4 class="text-start">¿Cuántas personas que han muerto por COVID estaban vacunadas? 3 de cada 100, según SSa</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    Tres de cada 100 personas que murieron por COVID-19 en México entre el 1 de enero y el 3 de agosto de 2021 estaban vacunadas contra esta enfermedad, de acuerdo con datos de la Secretaría de Salud. 
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://serendipia.digital/covid-19/cuantas-personas-que-han-muerto-por-covid-estaban-vacunadas/'" 
                    size="sm"> LEE MÁS SOBRE EL TEMA
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>

        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://serendipia.digital/tablero-covid-19-en-mexico//'">
                
                <b-card img-src="https://serendipia.digital/wp-content/uploads/2021/06/nota-tablero-covid.jpg" 
                img-alt="Todo México vacunado contra COVID-19 en octubre, prometió AMLO: ¿lo cumplirá?" 
                img-top >
                <h4 class="text-start">Tablero COVID-19 en México</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    Consulta las estadísticas sobre COVID-19 actualizadas todos los días con los datos de la Secretaría de Salud. Puedes conocer cómo evolucionan los contagios, las hospitalizaciones, las muertes y la campaña de vacunación.
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://serendipia.digital/tablero-covid-19-en-mexico/'" 
                    size="sm"> LEE MÁS SOBRE EL TEMA
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>

    </b-row>

    <b-row class="p-0 d-flex justify-content-center">

        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://serendipia.digital/covid-19/todo-mexico-vacunado-covid19-en-octubre/'">
                
                <b-card img-src="https://serendipia.digital/wp-content/uploads/2021/06/nota-amlo-vacunas.jpg" 
                img-alt="Todo México vacunado contra COVID-19 en octubre, prometió AMLO: ¿lo cumplirá?" 
                img-top >
                <h4 class="text-start">Todo México vacunado contra COVID-19 en octubre, prometió AMLO: ¿lo cumplirá?</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    El presidente López Obrador dijo que todo México estará vacunado contra la enfermedad COVID-19 en octubre. Pero ninguna de las promesas que ha hecho el gobierno sobre metas de vacunación ha sido cumplida hasta ahora.
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://serendipia.digital/covid-19/todo-mexico-vacunado-covid19-en-octubre/'" 
                    size="sm"> LEE MÁS SOBRE EL TEMA
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>

        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://serendipia.digital/covid-19/cuantas-vacunas-contra-covid-ha-aplicado-mexico/'">
                
                <b-card img-src="https://serendipia.digital/wp-content/uploads/2021/03/nota-cifras-vacunas.jpg" 
                img-alt="¿Cuántas vacunas contra COVID ha aplicado México?" 
                img-top >
                <h4 class="text-start">¿Cuántas vacunas contra COVID ha aplicado México?</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    La vacunación contra COVID-19 en México está en la segunda etapa, que incluye a personal de salud y personas mayores de 60 años. ¿Cómo avanza la inmunización en el país?
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://serendipia.digital/covid-19/cuantas-vacunas-contra-covid-ha-aplicado-mexico/'" 
                    size="sm"> LEE MÁS SOBRE EL TEMA
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>

    </b-row>

    <b-row class="p-0 d-flex justify-content-center">

        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://serendipia.digital/covid19-mx/muertes-por-covid-19-en-mexico-mapa/'">
                
                <b-card img-src="https://i1.wp.com/serendipia.digital/wp-content/uploads/2020/05/Screen-Shot-2020-05-12-at-9.16.18-PM.png?resize=960%2C1004&ssl=1" 
                img-alt="Muertes por COVID-19 en México. Mapa y gráficas por estado" 
                img-top >
                <h4 class="text-start">Muertes por COVID-19 en México. Mapa y gráficas por estado</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    ¿Qué porcentaje de las personas a las que les ha sido diagnosticado COVID-19 han muerto en cada estado de México? ¿Cuántas personas estaban hospitalizadas antes de morir? Esto es lo que dice la base de datos de la Secretaría de Salud.
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://serendipia.digital/covid19-mx/muertes-por-covid-19-en-mexico-mapa/'" 
                    size="sm"> LEE MÁS SOBRE EL TEMA
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>

        <b-col sm="6" md="4" class="mb-5 mt-3">

            <b-link :href="'https://contratoscovid.serendipia.digital/'">
                
                <b-card img-src="https://contratoscovid.serendipia.digital/tarjeta-comprascovid.png" 
                img-alt="Compras COVID MX" 
                img-top >
                <h4 class="text-start">Compras COVID MX</h4>
                <b-card-text class="mt-4 mb-4 text-start no-bold">
                    Entre enero de 2020 a la fecha, el gobierno de México ha adjudicado contratos por más de 20 mil millones de pesos para atender la pandemia de COVID-19.
                </b-card-text>
                <b-button 
                    class="mt-3 b-button-profile mb-1 rounded-pill border-0 align-items-center justify-content-center d-flex" 
                    :href="'https://contratoscovid.serendipia.digital/'" 
                    size="sm"> EXPLORA TODOS LOS CONTRATOS AQUÍ
                    </b-button>
                </b-card>
            
            </b-link>

        </b-col>
            
    </b-row>

</div>
    
</template>

<script>
export default {
    name: 'Nota',
}
</script>

<style scoped>
/* 
.card{
    min-height: 450px;
} */

img{
    object-fit: cover;
    border-radius: 20px;
    height: 200px;
    min-height: 80px
}

.no-bold{
    font-weight: lighter;
}

</style>