<template>
<div>
    <b-container>

      <b-row>
        <div class="col-10 offset-1">
          <div class="row">
          <!--personas vacunadas-->
          <b-col md="4" class="mr-2 ml-2 mb-3">
          <b-card class="text-center">
            <b-card-text>
              <p>Personas vacunadas con esquema completo</p>
              <h4 class="mb-0"><animated-number :value="esquemasCompletosHoy" :formatValue="formatToPrice" :duration="3000"/></h4>
              <p class="note-sub mt-0 mb-0 text-center">( {{(esquemasCompletosHoy * 100 / totalPersonas).toFixed(2)}}%) </p>
              <b-progress class="mt-2" :max="100" height="2rem">
                <b-progress-bar :value="esquemasCompletosHoy * 100 / totalPersonas" variant="warning">
                </b-progress-bar>
              </b-progress>
              <b-row>
                <b-col cols="6">
                  <p class="note-sub text-start">0</p>
                </b-col>
                <b-col cols="6">
                  <p class="note-sub text-end">{{Intl.NumberFormat('es-MX').format(totalPersonas)}}</p>
                </b-col>
              </b-row>
             

              <p>Personas vacunadas con medio esquema</p>
              <h4 class="mb-0"><animated-number :value="mediosesquemasHoy" :formatValue="formatToPrice" :duration="3000"/></h4>
              <p class="note-sub mb-0 mt-0 text-center">( {{(mediosesquemasHoy * 100 / totalPersonas).toFixed(2)}}%) </p>
              <b-progress class="mt-2" :max="100" height="2rem">
                <b-progress-bar :value="mediosesquemasHoy * 100 / totalPersonas" variant="secondary">
                </b-progress-bar>
              </b-progress>
              <b-row>
                <b-col cols="6">
                  <p class="note-sub text-start">0</p>
                </b-col>
                <b-col cols="6">
                  <p class="note-sub text-end">{{Intl.NumberFormat('es-MX').format(totalPersonas)}}</p>
                </b-col>
              </b-row>

              <p class="note-sub mt-3">
                 Se han completado esquemas del <strong>{{(esquemasCompletosHoy * 100 / totalPersonas).toFixed(2)}}%</strong> de la población mayor de 18 años al {{fecha}}. 
                 Falta el <strong>{{100 - (esquemasCompletosHoy * 100 / totalPersonas).toFixed(2)}}%</strong>
                de la población (<strong>{{ Intl.NumberFormat('es-MX').format(totalPersonas-esquemasCompletosHoy) }}</strong> personas sin vacunar con el esquema completo)
              </p>
             
            </b-card-text>
          </b-card>

        </b-col>

        <!--promedio personas vacunadas-->
        <b-col md="4" class="mr-2 ml-2 mb-3">
          <b-card class="text-center">
            <b-card-text>
              <p>Promedio diario de esquemas completos durante {{diffdaystart}} días</p>
              
              <h3><animated-number :value="(esquemasCompletosHoy / diffdaystart)" :formatValue="formatToPrice" :duration="3000"/></h3>
              <hr>
              <p>Días necesarios para tener a todo México vacunado contra la Covid-19 a este ritmo</p>
              <h3><animated-number :value="getdiasnecesarios(diffdaystart)" :formatValue="formatToPrice" :duration="3000"/></h3>
              <p class="note-sub mt-3">
                 A este ritmo la vacunación se completaría el <strong>{{getFechaNecesaria(fechaUnformat,getdiasnecesarios(diffdaystart))}}</strong>
              </p>
            </b-card-text>
          </b-card>

        </b-col>


        <b-col md="4" class="mr-2 ml-2 mb-3">
          <b-card class="text-center">
            <b-card-text>
              <div class="row">
                <div class="col">
                  <p>Días <br>transcurridos</p> 
              <h3 class="mb-0"><animated-number :value="diffdaystart" :formatValue="formatToPrice" :duration="3000"/></h3>
                </div>
                <div class="col">
                  <p>Días <br>restantes</p> 
              <h3 class="mb-0"><animated-number :value="diffday" :formatValue="formatToPrice" :duration="3000"/></h3>
                </div>
              </div>
              
              <p>para tener a todo México vacunado</p>
              <b-progress class="mt-2" :max="100" height="2rem">
                <b-progress-bar :value="diffdaystart * 100 /totalDays" variant="warning">
                </b-progress-bar>
              </b-progress>
              <b-row>
                <b-col cols="6">
                  <p class="note-sub text-start">24/12/2020</p>
                </b-col>
                <b-col cols="6">
                  <p class="note-sub text-end">31/10/2021</p>
                </b-col>
              </b-row>
              <p class="note-sub mt-3">
                El inicio de vacunación fue el 24 de Diciembre de 2020, desde esta fecha a octubre hay {{totalDays}} días. 
                Con el avance promedio actual, para alcanzar la meta se tendrían que completar <strong>{{  Intl.NumberFormat('es-MX').format((totalPersonas-esquemasCompletosHoy)/diffday)}}</strong>
                esquemas diariamente en los {{diffday}} días restantes.
              </p>
              
            </b-card-text>
          </b-card>
        </b-col>
        
        
        </div>
        </div>
      </b-row>

    </b-container>


</div>
    
</template>


<script>


import AnimatedNumber from "animated-number-vue";
import { mapGetters } from 'vuex'

import file from '../../public/esquemas-completos.json'

export default {
    name: 'Grafics',
    components: {
        AnimatedNumber
    },
    data(){
      return{
        // esquemasCompletosAyer: 12415247,
        esquemasCompletosHoy: file[file.length-1].esquemas_completos,
        mediosesquemasHoy: file[file.length-1].medios_esquemas,
        totalPersonas: 89484507,
      }
    },
    computed:{
      ...mapGetters({
        diffday: 'getDiff',
        diffdaystart: 'getDiffStart',
        totalDays: 'getTotalDays',
        fecha: 'getStringDateEsData',
        fechaUnformat: 'getFechaUnformat'
      }),
    },
    methods:{
      formatToPrice(value) {
        return `${Intl.NumberFormat().format(parseInt(value))}`;
      },
      getdiasnecesarios(diffdaystart){
        return (this.totalPersonas-this.esquemasCompletosHoy)/(this.esquemasCompletosHoy/diffdaystart)
      },
      getFechaNecesaria(fecha,dias){
        let date = new Date()
        date.setDate(fecha.getDate() + dias);
        
        let month = date.getMonth()+1
        let year = date.getFullYear()
        let day = date.getDate()

        const MESES_STRING_ES={
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      }

      const DEF_MES = month
      const mes_correct = MESES_STRING_ES[month] || DEF_MES
      
      return day+' de '+mes_correct+' de '+year
      }
    }
}
</script>

<style scoped>

p.note-sub{
  font-size: 12px;
  color: var(--greyspdark)
}

</style>