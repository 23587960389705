<template>
<div> 


  <div class="footer bg-grey-sp mt-5">    
    <!-- <div class="bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
              <div class="mt-4 pt-4 mb-4 pb-4">
                <div class="ad-container">
                  <Adsense
                    data-ad-client="ca-pub-5167929287009231"
                    data-ad-slot="2262975061"
                    data-ad-format="auto"
                    :data-full-width-responsive="true"
                  >
                  </Adsense>
                </div>
              </div>
          </div>
        </div>
        
      </div>
    </div> -->
    <div class="bg-dark">
      <Nota/>

      <p class="m-2 footer_note">Fecha de actualización: {{fecha}} con datos de la <a href="https://www.gob.mx/salud">Secretaría de Salud</a> al {{fechaData}}.</p>
  </div>
    <b-container class="pb-5 pt-5 mb-0">
      <b-row>
        <b-col cols="12" sm="3">
          <img src="../assets/Vacunas-logo-original.svg" alt="¿Todo todo México está vacunado? logo">
        </b-col>
        <b-col cols="12" sm="9">
          <div class="row mt-4 pt-4 mb-4 pb-4">
            <b-col md="6" sm="12">
              <p>hola@serendipia.digital</p>
            </b-col>
            <b-col md="6" sm="12">
              <p>
                <a href="https://serendipia.digital/" class="serendipia-logo">sp</a>
              </p>
              <p>
                <a href="https://twitter.com/serendipiadata" class="sp_ficon tw">tw</a>
                <a href="https://www.tiktok.com/@serendipiadata" class="sp_ficon tiktok">tk</a>
                <a href="https://www.facebook.com/SerendipiaData/" class="sp_ficon fb">fb</a>
                <a href="https://instagram.com/SerendipiaData" class="sp_ficon insta">ig</a>
                <a href="https://m.youtube.com/c/SerendipiaData" class="sp_ficon yt">yt</a>
              </p>
            </b-col>
          </div>
        </b-col>

      </b-row>
    </b-container>


  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import Nota from '../components/Nota'

export default {
  name: "Footer",
  computed:{
    ...mapGetters({
      fecha: 'getStringDateEs',
      fechaData: 'getStringDateEsData'
    })
  },
  components: {
    Nota
  }

}
</script>

<style scoped>

.footer{
  z-index: 1000;
  position: relative;
  bottom: 0;
}

.bg-grey-sp{
  z-index: 1030;
  background-color: #f3f3f3;
}

img{
  height: 100px;;
}

.card p.content-text.text-left{
  color: var(--greyspdark);
}



</style>